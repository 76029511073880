import React from 'react';
import Loadable from 'react-loadable';

function Loading(props) {
  if (props.error) {
    return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
  } else if (props.pastDelay) {
    return <div>Loading...</div>;
  } else {
    return null;
  }
}

export const LoadableChatBot = Loadable({
  loader: () => import('../containers/ChatBot'),
  loading: Loading,
  delay: 1000, // 1 seconds
});

export const LoadableManagerBot = Loadable({
  loader: () => import('../containers/ManagerBot'),
  loading: Loading,
  delay: 1000, // 1 seconds
});