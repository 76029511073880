let backendHost;
let app_env;

const hostname = window && window.location && window.location.hostname;

if (hostname.includes("localhost")) {
  backendHost = "http://localhost:3000";
  app_env = "LOCALHOST";
} else {
  backendHost = "https://" + hostname.replace("chat", "api");
  if (hostname.includes("eu") || hostname.includes("ksa") || hostname.includes("chat.peoplebox.ai")) {
    app_env = "PRODUCTION";
  } else {
    app_env = hostname.split(".")[1].toUpperCase()
  }
}

export const API_ROOT = `${backendHost}`;
export const APP_ENV = app_env;
