import ReactGA from "react-ga";
import { APP_ENV, API_ROOT } from '../config/api-config';
import ahoyJs from 'ahoy.js';

export const initGA = () => {    
  if(APP_ENV === 'PRODUCTION') {
    ReactGA.initialize([{trackingId: 'UA-149150790-1'},{trackingId: 'AW-789448602'}]); 
  }
}

export const PageView = () => {  
  if(APP_ENV === 'PRODUCTION') {
    ReactGA.pageview(window.location.pathname + window.location.search); 
  }
}

export const Event = (category, action, label) => {
  if(APP_ENV === 'PRODUCTION') {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  }
};

export const initAhoy = (headers) => {
  let config = {
    urlPrefix: API_ROOT,
    ...headers
  }
  ahoyJs.configure(config);

  return ahoyJs;
}