import React from 'react';
import { Row, Col } from 'antd';
import 'antd/dist/antd.css';

const NudgedTeam = () => {
  return (
    <Row>
      <div className="singletext-container" style={{'background':'#79b2fd', 'height': '100vh', 'color': 'white'}}>
        <div className="singletext-message">
          <Col span={24} style={{'textAlign':'center', 'fontSize': '23px', 'marginTop': '20%'}}>
            <p>Thanks! Your team members have been nudged on behalf of you. <span className="emoji" role="img" aria-label="victory">✌</span></p>
          </Col>
        </div>
      </div>
    </Row>
  )
}

export default NudgedTeam;
