import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import nova from '../../resources/nova-new-neutral-full.png';



const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    backgroundColor: '#1093f2',
    overflow: 'hidden'
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  botImageContainer: {
    textAlign: 'center'
  },
  botImage: {
    height: '175px',
    marginTop: '180px'
  },
  textContainer: {
    color: 'white',
    fontSize: '28px',
    marginTop: '50px',
  },
  textELement: {
    width: '50%',
    margin: '0 auto',
    textAlign: 'center'
  },
  goToChatButton: {
    color: 'white',
    textAlign: 'center',
    marginTop: '30px'
  },
  button: {
    color: 'white',
    fontSize: '23px',
    border: '1px solid #fff',
    textTransform: 'capitalize',
  }
});

class ChatComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: true
    }
  }

  decodeAuthToken = () => {
    return atob(this.props.match.params.auth_token);
  }

  handleClick = () => {
    const token = this.props.match.params.auth_token;
    const employeeChatId = this.props.match.params.employeeChatId;
    this.props.history.push(`/chatbot/employee_chats/${employeeChatId}/${token}`)
  }

  getChatButton = () => {
    const { classes } = this.props;
    if (this.state.showButton) {
      return (
        <div className={classes.goToChatButton}>
          <Button variant="outlined" color="inherit" className={classes.button} onClick={this.handleClick}>
            Go To Chat
          </Button>
        </div>
      )
    } else {
      return null;
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <div className={classes.botImageContainer}>
              <img src={nova} alt="Kyra" className={classes.botImage}/>
            </div>
            <div className={classes.textContainer}>
              <div className={classes.textELement}>
                  Seems, like you have already responded for the question. You can go to the chat window and see your responses by clicking below
              </div>
            </div>
            {this.getChatButton()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(withStyles(styles), withRouter)(ChatComplete);
