import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import history from "../history";
import NudgedTeam from "../components/NudgedTeam";
import { LoadableChatBot, LoadableManagerBot } from "../Loadable";
import ChatComplete from "../components/ChatComplete";
import { PageView, initGA } from "../Tracking";
import { APP_ENV } from "../config/api-config";

class Root extends Component {
  componentDidMount() {
    initGA();
    PageView();
  }

  renderLuckyOrange = () => {
    if (APP_ENV === "PRODUCTION" || APP_ENV === "DEMO") {
      window.__lo_site_id = 197547;
      const wa = document.createElement("script");
      wa.type = "text/javascript";
      wa.async = true;
      wa.src = "https://d10lpsik1i8c69.cloudfront.net/w.js";
      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(wa, s);
    }
  };

  render() {
    return (
      <>
        {this.renderLuckyOrange()}
        <Router history={history}>
          <div>
            <Route
              exact={true}
              path="/chatbot/employee_chats/:employeeChatId/:auth_token"
              component={LoadableChatBot}
            />
            <Route
              exact={true}
              path="/managerbot/manager_chats/:managerChatId/:auth_token"
              component={LoadableManagerBot}
            />
            <Route
              exact={true}
              path="/chatbot/employee_chats/:employeeChatId/chat-complete/:auth_token"
              component={ChatComplete}
            />
            <Route path="/nudged-team" component={NudgedTeam} />
          </div>
        </Router>
      </>
    );
  }
}

export default Root;
